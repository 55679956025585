import payload_plugin_RXnXvnMNoi from "/overbookd/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.24.4_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_CBAD8rvPjR from "/overbookd/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.8.6_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._qaf4ughqozhrxseyejbqcaxv3a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_sQz5G6oLjZ from "/overbookd/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.8.6_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._qaf4ughqozhrxseyejbqcaxv3a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_qko5XwkZMh from "/overbookd/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.8.6_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._qaf4ughqozhrxseyejbqcaxv3a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_foD73VBW9W from "/overbookd/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.8.6_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._qaf4ughqozhrxseyejbqcaxv3a/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PeldM8KnPx from "/overbookd/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.8.6_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._qaf4ughqozhrxseyejbqcaxv3a/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_HosGLtgWf7 from "/overbookd/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.8.6_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._qaf4ughqozhrxseyejbqcaxv3a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Fp0bIUB2Fu from "/overbookd/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.8.6_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._qaf4ughqozhrxseyejbqcaxv3a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_0MgjReGFPh from "/overbookd/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.24.4_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/overbookd/apps/web/.nuxt/components.plugin.mjs";
import prefetch_client_oLMl6ujTot from "/overbookd/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.8.6_eslint@9.14.0_jiti@2.4.0__ioredis@5.4._qaf4ughqozhrxseyejbqcaxv3a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chartjs_client_N7GULTnRpX from "/overbookd/apps/web/plugins/chartjs.client.ts";
import vue_html_secure_client_vi14lb5zJW from "/overbookd/apps/web/plugins/vue-html-secure.client.ts";
import vuetify_client_69rYAaPZnF from "/overbookd/apps/web/plugins/vuetify.client.ts";
export default [
  payload_plugin_RXnXvnMNoi,
  revive_payload_client_CBAD8rvPjR,
  unhead_sQz5G6oLjZ,
  router_qko5XwkZMh,
  payload_client_foD73VBW9W,
  navigation_repaint_client_PeldM8KnPx,
  check_outdated_build_client_HosGLtgWf7,
  chunk_reload_client_Fp0bIUB2Fu,
  plugin_vue3_0MgjReGFPh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_oLMl6ujTot,
  chartjs_client_N7GULTnRpX,
  vue_html_secure_client_vi14lb5zJW,
  vuetify_client_69rYAaPZnF
]